import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmailFirstForm } from '../emailFirstForm';
import {
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EmailFirstEmailFormInput,
} from '../inputs';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { useSessionStorage } from '../../../../hooks/useSessionStorage';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import {
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../../../pages/email-first/emailFirstRouteNames';
import checkEmailUniqueness from '../../../../services/checkEmailUniqueness';
import { useStateContext } from '../../../../modules/stateContext';
import { AgeDeclarationTitle } from './emailFirstEmailForm.style';
import { useConsentAge } from '../../../../context/consentAge';
import { getEmailFirstExperimentRoutes } from '../../../../experiments/otp_verification_experiment/components/emailFirstRouteNames';

const UNEXPECTED_ERROR = 'message.gracefulDeg.technicalProblems';

const activeFields = [EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

export const ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY =
    'oneTimePersistentUserIdentifierKey';

// ! THIS IS FOR THE OTP EXPERIMENT
// ! Replace where this function is called with the following after experiment (otpVerification)
/*
emailFirstFederated
    ? emailFirstPaths.password.path
    : emailFirstRoutes.password.path
*/

const getNextRoute = (otpVerification, mvt, routes) => {
    if (!otpVerification) return routes.password.path;

    const userBucket = mvt?.experiments?.acc_sireve_reg_email_verification;

    if (userBucket === 'after_email') {
        return routes.otp.path;
    }

    return routes.password.path;
};

export const EmailFirstEmailForm = () => {
    const [formErrors, setFormErrors] = useState();

    const {
        featureToggles: {
            consentAgeByCountry,
            persistentIdentifier,
            emailFirstFederated,
            otpVerification,
        },
        isFederated,
        mvt,
    } = useStateContext();

    const { emailFirstPaths } = otpVerification
        ? getEmailFirstExperimentRoutes(isFederated)
        : getEmailFirstRoutes(isFederated);

    const { sessionStorage } = useSessionStorage();

    const preSubmit = useCallback(async ({ fieldValues }) => {
        setFormErrors(undefined);

        try {
            const { success, message } = await checkEmailUniqueness(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
            );

            if (!success) {
                const messageId = emailFirstFederated
                    ? message?.email?.id
                    : message?.id;

                if (
                    persistentIdentifier &&
                    messageId === 'emailFirst.email.duplicate'
                ) {
                    sessionStorage?.setItem(
                        ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
                        fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                    );
                }

                if (!emailFirstFederated) {
                    setFormErrors(message);

                    return false;
                }

                const duplicateErrorMessage = isFederated
                    ? message?.federatedEmail
                    : message?.email;

                setFormErrors(duplicateErrorMessage || message);

                return false;
            }
        } catch (error) {
            setFormErrors(UNEXPECTED_ERROR);

            return false;
        }

        return true;
    }, []);
    const {
        fieldValues,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: getNextRoute(
            otpVerification,
            mvt,
            emailFirstFederated ? emailFirstPaths : emailFirstRoutes
        ),
        preSubmit,
    });

    const showExUkContent = useShowExUkContent();

    const { consentAge } = useConsentAge();

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formErrors}
            heading={
                <FormattedMessage
                    id={
                        showExUkContent
                            ? 'register.title.introductionExUk'
                            : 'emailFirst.emailForm.title'
                    }
                />
            }
            hideBackButton
            name="email-first-email-form"
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first"
            submitButtonLabel="button.continue.value"
            withSignInCta
        >
            {showExUkContent && (
                <AgeDeclarationTitle>
                    <FormattedMessage
                        id={
                            consentAgeByCountry
                                ? 'emailFirst.emailForm.ageDeclarationConsentAge.title'
                                : 'emailFirst.emailForm.ageDeclaration.title'
                        }
                        values={{
                            consentAge,
                        }}
                    />
                </AgeDeclarationTitle>
            )}
            <EmailFirstEmailFormInput
                defaultValue={
                    fieldValues &&
                    fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                }
            />
        </EmailFirstForm>
    );
};
