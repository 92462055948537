import { useEffect, useState } from 'react';
import renderQueryString from '../../../shared/urls/renderQueryString';
import { useStateContext } from '../../modules/stateContext';
import {
    AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL,
    AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL,
} from '../../../shared/endpoints/account';
import magicLinkAuthenticate from '../../services/magicLinkAuthenticate';
import { DEFAULT_SOURCE } from '../../../shared/data/analytics';
import authPages from '../../../shared/data/authPages';

const updatePtrtWithIntermediateSuccessPage = (
    ptrt,
    lang,
    sequenceId,
    context,
    featureToggles
) => {
    const successUrl = new URL(AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL);

    successUrl.searchParams.set('ptrt', ptrt);

    if (lang) {
        successUrl.searchParams.set('lang', lang);
    }

    if (sequenceId && featureToggles.magicLinkSequenceId) {
        successUrl.searchParams.set('sequenceId', sequenceId);
    }

    if (context && featureToggles.magicLinksContext) {
        successUrl.searchParams.set('context', context);
    }

    return successUrl.toString();
};

const getSessionUrlWithUpdatedPtrt = (
    sessionUrl,
    ptrtValue,
    lang,
    sequenceId,
    context,
    featureToggles
) => {
    const redirectUrl = new URL(sessionUrl);
    const state = JSON.parse(redirectUrl.searchParams.get('state'));
    state.ptrt = updatePtrtWithIntermediateSuccessPage(
        ptrtValue,
        lang,
        sequenceId,
        context,
        featureToggles
    );
    redirectUrl.searchParams.set('state', JSON.stringify(state));

    return redirectUrl.toString();
};

const redirectToMagicLinkErrorPage = (context, featureToggles) => {
    const query =
        featureToggles.magicLinksContext && context
            ? `?context=${context}`
            : '';

    window.location.assign(`${AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL}${query}`);
};

function useMagicLinkPolling(disablePollingOverride = false) {
    const userContext = useStateContext();
    const [pollingHasFailed, setPollingHasFailed] = useState(false);

    const tenSecondInterval = 1000 * 10;
    const fifteenMinutesInMilliseconds = 1000 * 60 * 15;

    const {
        featureToggles,
        signIn: {
            data: { email, jti },
        },
        lang,
        ptrt: { value: ptrtValue },
        sequenceId,
        context,
    } = userContext;

    if (
        featureToggles.magicLinksPollingAnalytics &&
        typeof window !== 'undefined'
    ) {
        document.addEventListener('visibilitychange', () => {
            document.dispatchEvent(
                new CustomEvent('bbc-user-event', {
                    detail: {
                        container: authPages.MAGIC_LINK_CONFIRMATION,
                        label: 'polling',
                        type: document.visibilityState,
                        source: DEFAULT_SOURCE,
                    },
                })
            );
        });
    }

    useEffect(() => {
        if (!featureToggles.magicLinksPolling || disablePollingOverride) {
            return undefined;
        }

        const intervalId = setInterval(async () => {
            const { sessionUrl, message } = await magicLinkAuthenticate(
                email,
                jti,
                renderQueryString.call(userContext)
            );

            if (sessionUrl) {
                const redirectUrl = getSessionUrlWithUpdatedPtrt(
                    sessionUrl,
                    ptrtValue,
                    lang,
                    sequenceId,
                    context,
                    featureToggles
                );

                window.location.assign(redirectUrl);
            } else if (message === 'expiredTokenError') {
                redirectToMagicLinkErrorPage(context, featureToggles);
            } else if (
                message === 'routeNotFound' ||
                message === 'clientError'
            ) {
                setPollingHasFailed(true);
                clearInterval(intervalId);

                if (
                    featureToggles.magicLinksPollingAnalytics &&
                    message === 'clientError'
                ) {
                    document.dispatchEvent(
                        new CustomEvent('bbc-user-event', {
                            detail: {
                                container: authPages.MAGIC_LINK_CONFIRMATION,
                                label: 'polling',
                                type: 'fallbackdisplayed',
                                source: DEFAULT_SOURCE,
                            },
                        })
                    );
                }
            } else if (message === 'serverError') {
                window.location.assign('/account/error');
            }
        }, tenSecondInterval);

        setTimeout(() => {
            clearInterval(intervalId);
            redirectToMagicLinkErrorPage(context, featureToggles);
        }, fifteenMinutesInMilliseconds);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return pollingHasFailed;
}

export default useMagicLinkPolling;
