import React, { Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
    EmailFirstEmailForm,
    EmailFirstPasswordForm,
    EmailFirstDetailsForm,
    EmailFirstDetailsFormExUk,
} from '../../../../components/forms/email-first';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import {
    basePath,
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../emailFirstRouteNames';
import { useEmailFirstRegistrationRoutes } from './useEmailFirstRegistrationRoutes';
import { useStateContext } from '../../../../modules/stateContext';
import { getEmailFirstExperimentRoutes } from '../../../../experiments/otp_verification_experiment/components/emailFirstRouteNames';
import Spinner from '../../../../components/shared/spinner';

const EmailFirstOTPForm = React.lazy(() =>
    import(
        '../../../../experiments/otp_verification_experiment/components/confirmation'
    )
);

const LazyLoadedOtpForm = () => (
    <Suspense
        fallback={
            <div className="pre-component-mount">
                <Spinner fillContainer />
            </div>
        }
    >
        <EmailFirstOTPForm />
    </Suspense>
);

export const EmailFirstRegistrationRoutes = () => {
    const { search } = useEmailFirstRegistrationRoutes();

    const showExUkContent = useShowExUkContent();

    const {
        isFederated,
        featureToggles: { emailFirstFederated, otpVerification },
    } = useStateContext();

    const { emailFirstPaths, emailFirstBasePath } = otpVerification
        ? getEmailFirstExperimentRoutes(isFederated)
        : getEmailFirstRoutes(isFederated);

    const DetailsForm = showExUkContent
        ? EmailFirstDetailsFormExUk
        : EmailFirstDetailsForm;

    const emailPath = emailFirstFederated
        ? emailFirstPaths.email.path
        : emailFirstRoutes.email.path;

    const passwordPath = emailFirstFederated
        ? emailFirstPaths.password.path
        : emailFirstRoutes.password.path;

    const detailsPath = emailFirstFederated
        ? emailFirstPaths.details.path
        : emailFirstRoutes.details.path;

    // ! THIS IS FOR THE OTP EXPERIMENT PLEASE REMOVE THIS AND ANY CODE RELATED TO THIS ONCE EXPERIMENT HAS FINISHED (otpVerification)
    const otpPath = emailFirstFederated
        ? emailFirstPaths?.otp?.path
        : emailFirstRoutes?.otp?.path;

    const rootPath = emailFirstFederated ? emailFirstBasePath : basePath;

    return (
        <Switch>
            <Route exact path={emailPath} component={EmailFirstEmailForm} />
            <Route
                exact
                path={passwordPath}
                component={EmailFirstPasswordForm}
            />
            {otpVerification && (
                <Route exact path={otpPath} component={LazyLoadedOtpForm} />
            )}
            <Route exact path={detailsPath} component={DetailsForm} />
            <Route path={`${rootPath}/*`}>
                <Redirect to={`${emailFirstPaths.email.path}${search}`} />
            </Route>
        </Switch>
    );
};
