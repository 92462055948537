import PropTypes from 'prop-types';
import {
    ab as ageBracketAllowlist,
    policy as policyAllowlist,
} from '../shared/data/allowlists';

const {
    arrayOf,
    bool,
    objectOf,
    oneOf,
    oneOfType,
    shape,
    string,
    object,
} = PropTypes;

const errorAttributeShape = objectOf(oneOfType([string, bool, object]));

const errorWithAttributesShape = shape({
    id: string.isRequired,
    attributes: oneOfType([errorAttributeShape, arrayOf(errorAttributeShape)])
        .isRequired,
});

export const errorShape = oneOfType([
    bool,
    string,
    errorWithAttributesShape,
    arrayOf(
        oneOfType([
            arrayOf(oneOfType([string, errorWithAttributesShape])),
            string,
            errorWithAttributesShape,
        ])
    ),
]);

export const ageBracketShape = oneOf(ageBracketAllowlist);

export const policyShape = oneOf(policyAllowlist);

export const ptrtShape = shape({
    isDefault: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
});

export const locationShape = shape({
    country: PropTypes.string,
    geoIpCountry: PropTypes.string,
    isUk: PropTypes.bool.isRequired,
    initiallyUk: PropTypes.bool.isRequired,
});

export const dateShape = shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
});

export const intlShape = shape({
    messages: arrayOf[PropTypes.string],
});

export const metaShape = shape({
    asyncValidating: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
});

export const inputShape = shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
});
