import { AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH } from '../../shared/endpoints/account';
import makeRequest from '../fetch';

const magicLinkAuthenticate = async (email, jti, params) => {
    try {
        return await makeRequest(
            `${AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH}${params}`,
            {
                method: 'post',
                body: { email, jti },
            }
        );
    } catch (error) {
        return { success: false, message: 'clientError' };
    }
};

export default magicLinkAuthenticate;
