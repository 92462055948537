import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { IFRAME_IDENTIFIER } from '../../../../constants';
import { LinkButtonForm } from '../../../../../forms/linkButton';
import { useStateContext } from '../../../../../../modules/stateContext';
import authPages from '../../../../../../../shared/data/authPages';
import userJourneys from '../../../../../../../shared/data/userJourneys';
import { AUTH_PATH } from '../../../../../../../shared/endpoints/account';
import renderQueryString from '../../../../../../../shared/urls/renderQueryString';

const eventContainer = authPages.IFRAME_PASSWORD_FORM;

export const magicLinkEventTracker = {
    container: eventContainer,
    name: 'magic-link-cta',
    result: AUTH_PATH,
    type: 'link',
};

export function IframePasswordMagicLinkButtonForm({ children }) {
    const userContext = useStateContext();

    const {
        signIn: { data, initialValues: { isMinimumAge } = {} },
    } = userContext;

    const username = data[IFRAME_IDENTIFIER.FIELD_NAME];

    const magicLinkUrl = useMemo(
        () =>
            `${AUTH_PATH}${renderQueryString.call(userContext, {
                strategy: userJourneys.MAGIC_LINK,
                userJourney: userJourneys.PASSWORDLESS,
            })}`,
        [userContext]
    );

    return (
        <LinkButtonForm
            action={magicLinkUrl}
            data={{
                username,
                isMinimumAge,
            }}
            eventTracker={magicLinkEventTracker}
            method="post"
        >
            {children}
        </LinkButtonForm>
    );
}

IframePasswordMagicLinkButtonForm.propTypes = {
    children: PropTypes.node.isRequired,
};
