import React from 'react';
import { CallToAction, CallToActionButton } from '@bbc-account/id-components';
import url from 'url';
import PropTypes from 'prop-types';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import nmaExternalHash from '../../../../shared/urls/nmaExternalHash';
import { IGNORE_VIEWS } from '../../../../shared/data/analytics';
import { isIframeLayout } from '../../../../shared/utilities/isIframeLayout';
import withEventTracking from '../../withEventTracking';
import connectToState from '../../connectToState';
import { StyledLinkButtonForm } from './applicationLinkContainer.style';

const createClickEventHandlerForButtonBaseOnLink = ({
    href,
    target = '_self',
}) => () => {
    window.open(href, target);
};

function renderLinkButtonForm({ children, data, href, method }) {
    return (
        <StyledLinkButtonForm action={href} data={data} method={method}>
            {children}
        </StyledLinkButtonForm>
    );
}

const ApplicationLinkContainer = props => {
    const {
        className,
        button,
        children,
        data,
        external,
        form,
        fullWidth,
        method,
        withExternalHash = props.external,
        withState = !props.external,
        href,
        target,
        primary,
        secondary,
        tertiary,
        bypassChameleon,
        userContext,
        createEventAttributes,
        ariaLabel,
        id,
        onClick,
        ...linkProps
    } = props;
    const shouldOpenInParentWindow =
        typeof userContext !== 'undefined' &&
        isIframeLayout(userContext.layout) &&
        target !== '_self';

    const externalHash = withExternalHash
        ? nmaExternalHash(userContext?.isNativeMobileApplication)
        : '';

    let statefulHref;

    if (typeof href === 'function') {
        const boundRenderQueryString = renderQueryString.bind(userContext);
        statefulHref = `${href(
            boundRenderQueryString,
            userContext
        )}${externalHash}`;
    } else {
        const queryString = withState
            ? renderQueryString.call(userContext)
            : '';

        statefulHref = `${href}${queryString}${externalHash}`;
    }

    if (shouldOpenInParentWindow) {
        statefulHref = statefulHref.replace(/[?&]layout=iframe/, '');
    }

    const eventAttributes = createEventAttributes
        ? createEventAttributes({
              type: 'link',
              result: url.parse(statefulHref).pathname,
              ignoreViews: IGNORE_VIEWS,
          })
        : {};

    if (form) {
        return renderLinkButtonForm({ children, data, href, method });
    }

    if (bypassChameleon) {
        let linkAttributes = {
            'aria-label': ariaLabel,
            className,
        };

        if (shouldOpenInParentWindow) {
            linkAttributes = {
                ...linkAttributes,
                target: '_parent',
                rel: 'noopener noreferrer',
            };
        }

        delete linkProps.createEventHandler;

        const concatenatedProps = {
            ...linkProps,
            ...eventAttributes,
            href: statefulHref,
            onClick,
            ...linkAttributes,
        };

        if (button) {
            const buttonProps = {
                ...concatenatedProps,
                onClick: createClickEventHandlerForButtonBaseOnLink(
                    concatenatedProps
                ),
            };

            delete buttonProps.href;
            delete buttonProps.target;
            delete buttonProps.rel;

            return (
                <button type="button" {...buttonProps}>
                    {children}
                </button>
            );
        }

        return <a {...concatenatedProps}>{children}</a>;
    }

    let variant = 'secondary';

    if (primary) {
        variant = 'primary';
    } else if (secondary) {
        variant = 'secondary';
    } else if (tertiary) {
        variant = 'tertiary';
    }

    return button ? (
        <CallToActionButton
            ariaLabel={ariaLabel}
            href={statefulHref}
            variant={variant}
            isFullWidth={fullWidth}
            id={id}
            eventAttributes={eventAttributes}
            hideUnderlineOnHover={fullWidth}
            onClick={onClick}
        >
            {children}
        </CallToActionButton>
    ) : (
        <CallToAction
            ariaLabel={ariaLabel}
            href={statefulHref}
            target={target}
            variant={variant}
            id={id}
            eventAttributes={eventAttributes}
            onClick={onClick}
        >
            {children}
        </CallToAction>
    );
};

ApplicationLinkContainer.propTypes = {
    ariaLabel: PropTypes.string,
    button: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    createEventAttributes: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    external: PropTypes.bool,
    form: PropTypes.bool,
    fullWidth: PropTypes.bool,
    method: PropTypes.oneOf(['get', 'post']),
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    target: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    grayscale: PropTypes.bool,
    bypassChameleon: PropTypes.bool,
    withExternalHash: PropTypes.bool,
    withState: PropTypes.bool,
    userContext: PropTypes.shape({
        isNativeMobileApplication: PropTypes.bool,
        layout: PropTypes.string,
    }),
};

ApplicationLinkContainer.defaultProps = {
    bypassChameleon: false,
};

export { ApplicationLinkContainer };
export default connectToState(withEventTracking(ApplicationLinkContainer));
