import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from '../../../components/shared/text';
import { StyledEmailFirstTextFormInputWrapper } from '../../../components/forms/email-first/inputs/postcode/emailFirstPostCodeFormInput.style';
import OtpApplicationLinkContainer from '../../../components/shared/applicationLink/experiment/applicationLinkContainer';
import { useStateContext } from '../../../modules/stateContext';
import { getEmailFirstExperimentRoutes } from './emailFirstRouteNames';

const ResendCta = ({ handleRedirect }) => {
    const { isFederated } = useStateContext();
    const emailFirstRoutes = getEmailFirstExperimentRoutes(isFederated);

    return (
        <StyledEmailFirstTextFormInputWrapper>
            <Text className="u-margin-top-double">
                <OtpApplicationLinkContainer
                    href={emailFirstRoutes.emailFirstPaths.email.path}
                    onClick={handleRedirect}
                    withState
                    primary
                >
                    <FormattedMessage id="Resend the code." />
                </OtpApplicationLinkContainer>
            </Text>

            <Text className="u-margin-top-double">
                <OtpApplicationLinkContainer
                    href={emailFirstRoutes.emailFirstPaths.email.path}
                    onClick={handleRedirect}
                    withState
                    primary
                >
                    <FormattedMessage id="Try again with a different email." />
                </OtpApplicationLinkContainer>
            </Text>
        </StyledEmailFirstTextFormInputWrapper>
    );
};

ResendCta.propTypes = {
    handleRedirect: PropTypes.func,
};

export default ResendCta;
