import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import renderQueryString from '../../../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../../../modules/stateContext';
import { IframeResizeObserver } from '../../../../../utilities/observers';
import { AuthIframePasswordInput } from '../../../inputs/iframe';
import AuthFormIframe from '../../../formIframe';
import {
    StyledIframePasswordAdditionalInfo,
    StyledIframePasswordForm,
} from './iframePassword.style';
import {
    IframePasswordCreatePasswordLink,
    IframePasswordForgotPasswordLink,
} from './resetPasswordLink';
import { IframePasswordMagicLinkButtonForm } from './magicLinkButtonForm';

export function IframePasswordForm() {
    const userContext = useStateContext();

    const {
        featureToggles: {
            createPasswordContent,
            createPasswordIframe,
            magicLinkSigninExUkIframe,
        },
        signIn: {
            context: { hasPassword },
        },
    } = userContext;

    const passwordAction = useMemo(
        () =>
            `/auth/password${renderQueryString.call(userContext, {
                layout: 'iframe',
            })}`,
        [userContext]
    );

    return (
        <IframeResizeObserver>
            <StyledIframePasswordForm>
                <AuthFormIframe
                    action={passwordAction}
                    data-testid="iframe-password-form"
                >
                    <AuthIframePasswordInput />
                    {hasPassword && (
                        <input
                            aria-hidden="true"
                            type="hidden"
                            value={hasPassword}
                            id="has-password"
                            name="hasPassword"
                        />
                    )}
                </AuthFormIframe>
                <StyledIframePasswordAdditionalInfo layout="space-between">
                    {createPasswordContent &&
                    createPasswordIframe &&
                    !hasPassword ? (
                        <IframePasswordCreatePasswordLink />
                    ) : (
                        <IframePasswordForgotPasswordLink />
                    )}
                    {magicLinkSigninExUkIframe && (
                        <IframePasswordMagicLinkButtonForm>
                            <FormattedMessage id="auth.password.magicLink.label" />
                        </IframePasswordMagicLinkButtonForm>
                    )}
                </StyledIframePasswordAdditionalInfo>
            </StyledIframePasswordForm>
        </IframeResizeObserver>
    );
}

IframePasswordForm.displayName = 'IframePasswordForm';
