import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Checkbox,
    Heading,
    InstructionContainer,
} from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import { useStateContext } from '../../../modules/stateContext';
import AuthForm from '../form';
import Text from '../../shared/text';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import { AUTH_PATH } from '../../../../shared/endpoints/account';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import FormError from '../../shared/formError';
import usePreventFormResubmission from '../../../hooks/usePreventFormResubmission';
import {
    StyledInstructionList,
    StyledListIcon,
} from './magicLinkConfirmation.style';
import useMagicLinkPolling from '../../../hooks/auth/useMagicLinkPolling';

const MagicLinkConfirmation = () => {
    const userContext = useStateContext();

    const intl = useIntl();

    const {
        signIn: {
            data: { email, jti, traceId },
            initialErrors,
        },
        featureToggles,
    } = userContext;

    const magicLinkAction = `/auth/magic-link${renderQueryString.call(
        userContext,
        { traceId }
    )}`;

    const [checked, setChecked] = useState(false);

    const showCheckboxError = !!initialErrors.confirmation && !checked;

    usePreventFormResubmission();

    const shouldNotUsePolling =
        !!initialErrors.confirmation || !featureToggles.magicLinksPolling;

    const pollingHasFailed = useMagicLinkPolling(shouldNotUsePolling);

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.checkEmail.title" />}
            showServiceIdentifiers
        >
            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.introduction" />{' '}
                <span className="u-bold">{email}</span>
            </Text>

            {featureToggles.magicLinksUxUpdate && (
                <Text
                    className={
                        featureToggles.magicLinksPolling &&
                        'u-margin-bottom-none'
                    }
                >
                    <FormattedMessage
                        id="auth.magicLink.checkEmail.activeDuration"
                        values={{
                            b: content => (
                                <span className="u-bold">{content}</span>
                            ),
                        }}
                    />
                </Text>
            )}

            {!shouldNotUsePolling && !pollingHasFailed && (
                <Text>
                    <FormattedMessage id="auth.magicLink.checkEmail.pollingPageRefresh" />
                </Text>
            )}

            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.weDoThis" />
            </Text>
            <Text>
                <FormattedMessage id="auth.magicLink.checkEmail.checkSpam" />
            </Text>

            {featureToggles.magicLinksUxUpdate ? (
                <InstructionContainer>
                    <Heading level={2} fontScale="indexHeadlineSmall">
                        <FormattedMessage id="auth.magicLink.checkEmail.whatNext" />
                    </Heading>

                    <StyledInstructionList>
                        <Text listItem>
                            <StyledListIcon>1</StyledListIcon>
                            <FormattedMessage id="auth.magicLink.checkEmail.clickButton" />
                        </Text>
                        <Text listItem>
                            <StyledListIcon>2</StyledListIcon>
                            <FormattedMessage id="auth.magicLink.checkEmail.comeBack" />
                        </Text>
                    </StyledInstructionList>
                </InstructionContainer>
            ) : (
                <>
                    <Heading level={2} fontScale="sectionHeading">
                        <FormattedMessage id="auth.magicLink.checkEmail.whatNext" />
                    </Heading>

                    <ol>
                        <Text listItem>
                            1.{' '}
                            <FormattedMessage id="auth.magicLink.checkEmail.clickButton" />
                        </Text>
                        <Text listItem>
                            2.{' '}
                            <FormattedMessage id="auth.magicLink.checkEmail.comeBack" />
                        </Text>
                    </ol>
                </>
            )}

            {(shouldNotUsePolling || pollingHasFailed) && (
                <AuthForm
                    action={magicLinkAction}
                    buttonTranslationKey="button.continue.value"
                >
                    <input
                        aria-hidden="true"
                        type="hidden"
                        value={email}
                        id="email-input"
                        name="email"
                    />
                    <input
                        aria-hidden="true"
                        type="hidden"
                        value={jti}
                        id="jti-input"
                        name="jti"
                    />
                    <Checkbox
                        label={intl.formatMessage({
                            id: 'auth.magicLink.checkEmail.confirmation',
                        })}
                        name="confirmation"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        labelSize="small"
                        variant="grayscale"
                        errorComponent={
                            showCheckboxError && (
                                <FormError
                                    error={initialErrors.confirmation}
                                    name="confirmation"
                                    migrateToIdComponents
                                />
                            )
                        }
                    />
                </AuthForm>
            )}
            <Text className="u-margin-top-double">
                <ApplicationLinkContainer
                    href={AUTH_PATH}
                    withState
                    primary={featureToggles.magicLinksUxUpdate}
                >
                    <FormattedMessage id="auth.magicLink.checkEmail.resendEmail" />
                </ApplicationLinkContainer>
            </Text>
            <Text>
                <ApplicationLinkContainer
                    href={() => {
                        return `${AUTH_PATH}${renderQueryString.call(
                            userContext,
                            {},
                            ['userJourney']
                        )}`;
                    }}
                    primary={featureToggles.magicLinksUxUpdate}
                >
                    <FormattedMessage id="auth.magicLink.checkEmail.usePassword" />
                </ApplicationLinkContainer>
            </Text>
        </Layout>
    );
};

export default MagicLinkConfirmation;
