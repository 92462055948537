import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { email as emailValidator } from '../../../../shared/validation/validators';
import Explainer from '../explainer';
import TextInput from '../textInput';
import GuardianEmailFieldNotifications from '../guardianEmailFieldNotifications';

const validator = email => emailValidator.validate([email]);

const PermissionsManagementEmailField = props => (
    <Field
        id="permissionsManagementEmail-input"
        name="permissionsManagementEmail"
        autoComplete="email"
        required
        type="text"
        label={<FormattedMessage id="label.guardianEmail" />}
        component={TextInput}
        validate={validator}
        {...props}
    >
        <Explainer
            explainers={[
                <FormattedMessage id="explainer.message.childSafety" />,
            ]}
            name="permissionsManagementEmail"
        />
        <GuardianEmailFieldNotifications />
    </Field>
);

PermissionsManagementEmailField.displayName = 'PermissionsEmailField';

export default PermissionsManagementEmailField;
