import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isCrownDependency } from '../../../../../../shared/data/crownDependencies';
import {
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../../emailFirstForm';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_GENDER_FORM_INPUT_NAME,
    EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME,
    EmailFirstDateOfBirthField,
    EmailFirstGenderFormInput,
    EmailFirstPostCodeFormInput,
} from '../../inputs';
import { useEmailFirstForm } from '../../useEmailFirstForm';
import { StyledTermsAndConditions } from '../emailFirstDetailsForm.style';
import { useStateContext } from '../../../../../modules/stateContext';
import { getEmailFirstExperimentRoutes } from '../../../../../experiments/otp_verification_experiment/components/emailFirstRouteNames';

const activeFields = [
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_GENDER_FORM_INPUT_NAME,
    EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME,
];

// TODO the countryCode is hardcoded as we have removed the
// field for the user to input. Should this be handled in some other way?
const countryCode = 'gb';

// ! THIS IS EXPERIMENT CODE, PLEASE REMOVE WHEN OTP EXPERIMENT IS OVER
// ! CALL FOR THIS FUNCTION CAN BE FULLY REMOVED WHEN DEFINING nextRoute (Example below of what object should look like)
const getNextRoute = (otpVerification, mvt, routes) => {
    if (!otpVerification) return undefined;

    const userBucket = mvt?.experiments?.acc_sireve_reg_email_verification;

    if (userBucket === 'after_details') {
        return routes.otp.path;
    }

    return undefined;
};

// ! THIS IS EXPERIMENT CODE, PLEASE REMOVE WHEN OTP EXPERIMENT IS OVER
/* 

When remove this experiment change the object for useEmailFirstForm to:

{
    activeFields,
    countryCode,
    prevRoute: emailFirstFederated
        ? emailFirstPaths.password.path
        : emailFirstRoutes.password.path,
    submitToRegister: true,
}
*/
const getSubmitStatus = (otpVerification, mvt) => {
    if (!otpVerification) return true;

    const userBucket = mvt?.experiments?.acc_sireve_reg_email_verification;

    if (userBucket === 'after_details') {
        return false;
    }

    return true;
};

export const EmailFirstDetailsForm = () => {
    const {
        featureToggles: { emailFirstFederated, otpVerification },
        isFederated,
        mvt,
    } = useStateContext();

    const { emailFirstPaths } = otpVerification
        ? getEmailFirstExperimentRoutes(isFederated, mvt)
        : getEmailFirstRoutes(isFederated);

    const shouldSubmit = getSubmitStatus(otpVerification, mvt);

    const {
        fieldValues,
        formError,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        countryCode,
        prevRoute: emailFirstFederated
            ? emailFirstPaths.password.path
            : emailFirstRoutes.password.path,
        nextRoute: getNextRoute(otpVerification, mvt, emailFirstPaths),
        submitToRegister: shouldSubmit,
    });

    const isInternational = !isCrownDependency(countryCode);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={<FormattedMessage id="emailFirst.detailsForm.title" />}
            formError={formError}
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first-details"
            submitButtonLabel={
                shouldSubmit
                    ? 'register.button.submit.value'
                    : 'button.continue.value'
            }
        >
            <EmailFirstDateOfBirthField />
            <EmailFirstPostCodeFormInput
                defaultValue={fieldValues && fieldValues.postcode}
            />
            <EmailFirstGenderFormInput
                defaultValue={(fieldValues && fieldValues.gender) || ''}
            />
            <StyledTermsAndConditions
                isInternational={isInternational}
                linkTarget="_blank"
                messageId="register.message.acceptTerms"
            />
        </EmailFirstForm>
    );
};
