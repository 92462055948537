import { styled } from 'styled-components';
import { LinkButtonForm, StyledButtonInline } from '../../forms/linkButton';

export const StyledLinkButtonForm = styled(LinkButtonForm)`
    display: inline-block;

    ${StyledButtonInline} {
        color: inherit;
        font-weight: 500;
        text-decoration-color: inherit;
    }
`;
