import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
    isValid,
    isInvalid,
} from '../../../shared/validation/validationResult';
import FormError from './formError';
import { errorShape } from '../../propTypes';

function getErrorMessage(
    name,
    error,
    hideErrorMessage,
    additionalErrorSentence,
    isWarning
) {
    if (hideErrorMessage || isValid(error) || typeof error === 'undefined') {
        return null;
    }

    if (additionalErrorSentence) {
        error = Array.isArray(error)
            ? [...error, '. ', additionalErrorSentence]
            : [error, ' ', additionalErrorSentence];
    }

    return (
        <FormError
            className="field__error"
            error={error}
            name={name}
            isAssertive
            isWarning={isWarning}
        />
    );
}

class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.setValueToInitialDomValue = this.setValueToInitialDomValue.bind(
            this
        );

        this.onChange = this.onChange.bind(this);

        this.setInputRef = element => {
            this.input = element;
        };
    }

    componentDidMount() {
        this.setValueToInitialDomValue();
    }

    onChange(...args) {
        const { initialError, removeInitialStateErrors, input } = this.props;

        if (initialError && typeof removeInitialStateErrors === 'function') {
            removeInitialStateErrors(input.name);
        }

        input.onChange(...args);
    }

    setValueToInitialDomValue() {
        this.props.input.onChange(this.input.value);
    }

    render() {
        const {
            additionalErrorSentence,
            additionalFieldClasses,
            additionalInputClasses,
            ariaDescribedBy,
            autoComplete,
            children,
            id,
            info,
            initialError,
            inputChildren,
            isWarning,
            label,
            meta: { asyncValidating, active, error, pristine, touched },
            input: { name, value },
            pattern,
            placeholder,
            inputMode,
            readOnly,
            required,
            showValid,
            hideErrorMessage,
            type,
        } = this.props;

        const showValidationIndicators = touched && !active;

        const shownError =
            pristine &&
            typeof initialError !== 'undefined' &&
            isInvalid(initialError)
                ? initialError
                : showValidationIndicators && error;

        const isFieldValid =
            showValidationIndicators &&
            (typeof shownError === 'undefined' || isValid(shownError)) &&
            !asyncValidating;

        const fieldClasses = classnames(
            'field',
            {
                'field--active': Boolean(value),
                'field--with-icon field--read-only': readOnly,
                'field--with-icon field--valid':
                    showValid &&
                    showValidationIndicators &&
                    (isFieldValid || isWarning),
                'field--invalid': shownError && !isFieldValid && !isWarning,
            },
            additionalFieldClasses
        );

        const inputClasses = classnames('field__input', additionalInputClasses);

        const ariaInvalid = !touched || active ? false : !isFieldValid;

        const ariaRequired = required ? 'true' : undefined;

        return (
            <div id={name} className={fieldClasses}>
                <div className="field__input-container">
                    <input
                        className={inputClasses}
                        id={id}
                        type={type}
                        pattern={pattern}
                        placeholder={placeholder}
                        required={required}
                        readOnly={readOnly}
                        aria-required={ariaRequired}
                        aria-invalid={ariaInvalid}
                        aria-describedby={ariaDescribedBy}
                        autoComplete={autoComplete}
                        autoCapitalize="none"
                        autoCorrect="off"
                        inputMode={inputMode}
                        {...this.props.input}
                        ref={this.setInputRef}
                        onChange={this.onChange}
                    />
                    <label
                        className="field__label"
                        id={`${name}-label`}
                        htmlFor={id}
                    >
                        {label}
                    </label>

                    <div className="field__decoration" />
                    {inputChildren}
                </div>
                {getErrorMessage(
                    name,
                    shownError,
                    hideErrorMessage,
                    additionalErrorSentence,
                    isWarning
                )}
                {info && <div className="field__info">{info}</div>}
                {children}
            </div>
        );
    }
}

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
    additionalErrorSentence: PropTypes.string,
    additionalFieldClasses: PropTypes.string,
    additionalInputClasses: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    autoComplete: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    info: PropTypes.node,
    initialError: errorShape,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    inputChildren: PropTypes.node,
    inputMode: PropTypes.string,
    isWarning: PropTypes.bool,
    label: PropTypes.node,
    meta: PropTypes.shape({
        asyncValidating: PropTypes.bool.isRequired,
        active: PropTypes.bool.isRequired,
        error: errorShape,
        pristine: PropTypes.bool.isRequired,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    removeInitialStateErrors: PropTypes.func,
    showValid: PropTypes.bool,
    hideErrorMessage: PropTypes.bool,
    type: PropTypes.string,
};

TextInput.defaultProps = {
    additionalFieldClasses: '',
    additionalInputClasses: '',
    readOnly: false,
    required: false,
    showValid: true,
    hideErrorMessage: false,
    type: 'text',
};

export default TextInput;
