/*
 *  HELLO! THIS CODE IS FOR EXPERIMENTS ONLY
 *  DO NOT USE THIS IN PRODUCTION CODE
 */

export const basePath = '/auth/register';
export const baseFederatedPath = '/auth/register/federated';

export const emailFirstExperimentRoutes = {
    details: {
        path: `${basePath}/details`,
        title: `emailFirst.page.title.register.details`,
    },
    email: {
        path: `${basePath}/email`,
        title: `emailFirst.page.title.register.email`,
    },
    password: {
        path: `${basePath}/password`,
        title: `emailFirst.page.title.register.password`,
    },
    otp: {
        path: `${basePath}/otp`,
        title: `emailFirst.page.title.register.otp`,
    },
};

export const emailFirstFederatedExperimentRoutes = {
    details: {
        path: `${baseFederatedPath}/details`,
        title: `emailFirst.page.title.register.details`,
    },
    email: {
        path: `${baseFederatedPath}/email`,
        title: `emailFirst.page.title.register.email`,
    },
    password: {
        path: `${baseFederatedPath}/password`,
        title: `emailFirst.page.title.register.password`,
    },
    otp: {
        path: `${baseFederatedPath}/otp`,
        title: `emailFirst.page.title.register.otp`,
    },
};

export const getEmailFirstExperimentRoutes = isFederated => {
    if (isFederated) {
        return {
            emailFirstPaths: emailFirstFederatedExperimentRoutes,
            emailFirstBasePath: baseFederatedPath,
        };
    }

    return {
        emailFirstPaths: emailFirstExperimentRoutes,
        emailFirstBasePath: basePath,
    };
};
