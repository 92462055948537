import React, { useMemo } from 'react';
import { TextInput } from '@bbc-account/id-components';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { password as validatePassword } from '../../../../../shared/validation/validators';
import { useStateContext } from '../../../../modules/stateContext';
import FormError from '../../../shared/formError';
import { AuthSubmitButton } from '../../buttons';
import { IFRAME_IDENTIFIER, IFRAME_PASSWORD } from '../../constants';
import { magicLinkEventTracker } from '../../pages/iframe/password/magicLinkButtonForm';
import { AuthIframeInputWithButton } from './authIframeInputWithButton';

function getUrlFromError(error) {
    const {
        attributes: { href },
    } = error;

    return new URL(href);
}

function convertErrorForLinkButtonForm(error, { isMinimumAge, username }) {
    const url = getUrlFromError(error);

    const { searchParams } = url;

    const { attributes } = error;

    searchParams.set('strategy', 'magicLink');
    searchParams.set('userJourney', 'passwordless');

    return {
        ...error,
        attributes: {
            ...attributes,
            data: {
                username,
                isMinimumAge,
            },
            eventTracker: magicLinkEventTracker,
            form: true,
            href: url.toString(),
            method: 'post',
        },
    };
}

function parseErrorWithLink(error, data) {
    const url = getUrlFromError(error);

    const { searchParams } = url;

    const userJourney = searchParams.get('userJourney');

    return userJourney === 'magicLink'
        ? convertErrorForLinkButtonForm(error, data)
        : error;
}

function parseError(error, data) {
    return typeof error === 'object' && error.attributes?.href
        ? parseErrorWithLink(error, data)
        : error;
}

export const AuthIframePasswordInput = () => {
    const userContext = useStateContext();

    const {
        signIn: { data, initialErrors, initialValues: { isMinimumAge } = {} },
    } = userContext;

    const username = data[IFRAME_IDENTIFIER.FIELD_NAME];

    const { value, state, eventHandlers } = useFieldState(
        IFRAME_PASSWORD.FIELD_NAME
    );

    const { validate, errors } = useFieldValidation(
        IFRAME_PASSWORD.FIELD_NAME,
        validatePassword.validate
    );

    const generalError = initialErrors?.general;

    const error = useMemo(
        () => parseError(errors || generalError, { isMinimumAge, username }),
        [errors, generalError, isMinimumAge, username]
    );

    const hasError = Array.isArray(error) ? error.length > 0 : !!error;

    const showError = hasError && !state.active;

    return (
        <>
            <input
                aria-hidden="true"
                className="u-hide"
                type={IFRAME_IDENTIFIER.TYPE}
                value={username}
                id={IFRAME_IDENTIFIER.ID}
                autoComplete="username"
                name={IFRAME_IDENTIFIER.FIELD_NAME}
                readOnly
            />
            <AuthIframeInputWithButton
                button={
                    <AuthSubmitButton isFullWidth variant="focus-outlined">
                        Sign in
                    </AuthSubmitButton>
                }
                input={
                    <TextInput
                        minimal
                        label={IFRAME_PASSWORD.LABEL}
                        id={IFRAME_PASSWORD.ID}
                        name={IFRAME_PASSWORD.FIELD_NAME}
                        type={IFRAME_PASSWORD.TYPE}
                        value={value || ''}
                        autoComplete="current-password"
                        eventHandlers={{
                            ...eventHandlers,
                            onBlur: event => {
                                validate([event.target.value]);
                                eventHandlers.onBlur(event);
                            },
                        }}
                        isInvalid={hasError}
                        message={
                            showError ? (
                                <FormError
                                    data-testid="password-input-form-error"
                                    error={error}
                                    name={IFRAME_PASSWORD.FIELD_NAME}
                                    migrateToIdComponents
                                />
                            ) : null
                        }
                    />
                }
            />
        </>
    );
};

AuthIframePasswordInput.displayName = 'AuthIframePasswordInput';
