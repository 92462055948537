import React from 'react';
import { IframeResizeObserver } from '../../../../../utilities/observers';
import { AuthIframeIdentifierInput } from '../../../inputs/iframe';
import AuthFormIframe from '../../../formIframe';

export function IframeIdentifierForm() {
    return (
        <IframeResizeObserver>
            <AuthFormIframe data-testid="iframe-identifier-form">
                <AuthIframeIdentifierInput />
                <input name="isMinimumAge" value="true" type="hidden" />
            </AuthFormIframe>
        </IframeResizeObserver>
    );
}

IframeIdentifierForm.displayName = 'IframeIdentifierForm';
