import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { emailOrUsername as validateEmailOrUsername } from '../../../../shared/validation/validators';
import TextInput from '../textInput';

// TODO: see if we can rename the name to emailOrUsername or something

const EmailOrUsernameField = props => (
    <Field
        id="user-identifier-input"
        name="username"
        component={TextInput}
        autoComplete="username"
        type="text"
        required
        label={<FormattedMessage id="common.label.emailOrUsername" />}
        validate={[validateEmailOrUsername.validate]}
        {...props}
    />
);

EmailOrUsernameField.displayName = 'EmailOrUsernameField';

EmailOrUsernameField.propTypes = {
    validate: PropTypes.func,
    label: PropTypes.node,
};

export default EmailOrUsernameField;
